import  {Card}  from "@mui/material";
import { styled } from "@mui/material/styles";


export default styled(Card)(({theme, ownerState}) => {
    const { palette, type } = theme;
    const {  grey } = palette;
    const { hasBorder } = ownerState;
    let border = ''
    
    if(type === "lightTheme")
    {
        border = `2px solid ${grey[300]}`
    }
    else
    {
        if(hasBorder)
        {   
            border = `1px outset ${grey[300]}`
        }
    }

    return {
        border: border
    }
});
