

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-select components
import Select from "react-select";

//  React base styles
import colors from "assets/theme/base/colors";

// Custom styles for VuiSelect
import styles from "components/VuiSelect/styles";

const VuiSelect = forwardRef(({ size, error, success, disabled, ...rest }, ref) => {
  const { light } = colors;
  return (
    <Select
      {...rest}
      ref={ref}
      closeMenuOnSelect={true}
      styles={styles(size, error, success)}
      isDisabled = {disabled}
    />
  );
});

// Setting default values for the props of VuiSelect
VuiSelect.defaultProps = {
  size: "medium",
  error: false,
  success: false,
  disabled:false,
};

// Typechecking props for the VuiSelect
VuiSelect.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default VuiSelect;
