import { forwardRef } from "react";
import VuiCardRoot from "components/VuiCard/VuiCardRoot";

const VuiCard = forwardRef(({color, children, hasBorder, ...rest}, ref) => (
    <VuiCardRoot 
    {...rest}
    ownerState={{ hasBorder }}
    ref = {ref}
    >
        {children}
    </VuiCardRoot>
))


VuiCard.defaultProps = {
    hasBorder: false
}


export default VuiCard;