import {useEffect, useState} from "react";
import {useOktaAuth} from "@okta/okta-react";
import config from "../../../../../config";
import IconButton from "@mui/material/IconButton";
import VuiTypography from "../../../../../components/VuiTypography";
import {Autocomplete, TextField} from "@mui/material";
export const UserImpersonation = ({ navbarIconButton, userInfo, onUserChange }) => {
    const [isErs, setIsErs] = useState(false);
    const [users, setUsers] = useState([]);
    const [value, setValue] = useState(null);
    const { authState } = useOktaAuth();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const options = {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authState.accessToken.accessToken}`,
                    },
                };

                const ersResponse = await fetch(`${config.okta.platform_url}/WLC/isErs`, options);
                if (!ersResponse.ok) throw new Error("Failed to fetch isErs status");

                const isErsData = await ersResponse.json();
                if (isErsData) {
                    setIsErs(true);
                    const userResponse = await fetch(`${config.okta.platform_url}/WLC/getUsers`, options);
                    if (!userResponse.ok) throw new Error("Failed to fetch user list");

                    const userList = await userResponse.json();
                    if (userList.users) setUsers(userList.users);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [authState.accessToken.accessToken]);

    const handleUserSelect = (event, newValue) => {
        setValue(newValue);
        if (onUserChange) onUserChange(newValue);
    };

    return (
        <IconButton
            sx={navbarIconButton}
            size="small"
            disabled={!isErs || users.length === 0}
        >
            {isErs && users.length >= 0 ? (
                <>
                    <VuiTypography variant="button" fontWeight="medium">
                        {value == null
                            ? "Select a User to Impersonate"
                            : `Impersonation Mode ${value}`}
                    </VuiTypography>
                    <Autocomplete
                        options={users}
                        value={value}
                        onChange={handleUserSelect}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                        )}
                        getOptionLabel={(option) => option || ""}
                        filterOptions={(options, { inputValue }) =>
                            options
                                .filter((option) =>
                                    option.toLowerCase().includes(inputValue.toLowerCase())
                                )
                                .slice(0, 10)
                        }
                        isOptionEqualToValue={(option, value) => option === value}
                    />
                </>
            ) : (
                <VuiTypography variant="button" fontWeight="medium">
                    Welcome {userInfo?.name}!
                </VuiTypography>
            )}
        </IconButton>
    );
};