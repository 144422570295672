

/** 
  All of the routes for the  React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

//  React layouts
import UniversalNotifier from "layouts/pages/universal-notifier";


import { LoginCallback } from '@okta/okta-react';

// React icons
import { IoDocument } from "react-icons/io5";
import { IoBuild } from "react-icons/io5";



const routes = [
  {
    collapse: [
      {
        name: "Okta",
        key: "okta",
        route: "/auth/login",
        component: LoginCallback,
        authRequired: false,
      }
    ],
    // collapse: [
    //   {
    //     name: "Sign in",
    //     key: "signin",
    //     route: "/authentication/sign-in/basic",
    //     component: Basic,
    //     authRequired: false,
    //   }
    // ],
  },
  { type: "title", title: "Pages", key: "title-pages" },
  {
    type: "collapse",
    name: "Universal Notifier",
    key: "Universal Notifier",
    icon: <IoBuild size="15px" color="inherit" />,
    collapse: [
      {
        name: "Preferences",
        key: "preferences",
        route: "/applications/universal-notifier",
        component: UniversalNotifier,
        authRequired: true,
      },
    ],
  },
  // {
  //   type: "collapse",
  //   name: "Posture",
  //   key: "posture",
  //   icon: <IoBuild size="15px" color="inherit" />,
  //   collapse: [
  //     {
  //       name: "Alerts",
  //       key: "alerts",
  //       route: "/applications/alerts",
  //       component: Alerts,
  //       authRequired: false,
  //     }
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "About-ERS",
  //   key: "About-ERS",
  //   icon: <IoBuild size="15px" color="inherit" />,
  //   collapse: [],
  // },
  // {
  //   type: "collapse",
  //   name: "Documents",
  //   key: "Documents",
  //   icon: <IoBuild size="15px" color="inherit" />,
  //   collapse: [
  //     {
  //       name: "Okta",
  //       key: "okta",
  //       route: "/auth/login",
  //       component: LoginCallback,
  //       authRequired: false,
  //     }
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Policies",
  //   key: "Policies",
  //   icon: <IoBuild size="15px" color="inherit" />,
  //   collapse: []
  // },
  // {
  //   type: "collapse",
  //   name: "Suppressions",
  //   key: "Suppressions",
  //   icon: <IoBuild size="15px" color="inherit" />,
  //   collapse: [],
  // },
];

export default routes;
