import  {Checkbox}  from "@mui/material";
import { styled } from "@mui/material/styles";


export default styled(Checkbox)(({theme, ownerState}) => {
    const { palette, type } = theme;
    const { white, dark, grey } = palette;
    return {
        color: type === "lightTheme" ? dark.main : white.main,
        '&.Mui-checked': {
            color: type === "lightTheme" ? dark.main : white.main,
        },
        '&.Mui-disabled': {
            color: type === "lightTheme" ? grey[300] : grey[600],
        },
    }
});
