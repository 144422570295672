//  React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// General page components
import NotifierPreferences from "layouts/pages/universal-notifier/components/NotifierPreferences";
import {useState} from "react";

function General() {
  const [selectedUser, setSelectedUser] = useState(null);
  return (
    <DashboardLayout>
      <DashboardNavbar onUserChange={setSelectedUser}/>
        <NotifierPreferences selectedUser={selectedUser} />
      <Footer />
    </DashboardLayout>
  );
}

export default General;
