// @mui material components
import { Grid } from "@mui/material";

//  components
import VuiTypography from "components/VuiTypography";
import VuiSwitch from "components/VuiSwitch";
import VuiButton from "components/VuiButton";
import VuiCheckbox from "components/VuiCheckbox"
import VuiCard from "components/VuiCard";

// General page component
import VuiInput from "components/VuiInput";

// Data
import React, { useState } from 'react';

function WorkloadsSelector({userWorkloads, selectedWorkloads, setSelectedWorkloads, viewingWorkloads, setViewingWorkloads, typesEnabled, userCanEdit, determineWorkloadEnableOrDisable, hasWorkloads}) {
    //Get system color scheme
    const[colorScheme, setColorScheme] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light")
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      setColorScheme(event.matches ? "dark" : "light")
    });

    const [filter, setFilter] = useState(''); //This will filter the userWorkloads for what the user is typing into the input box
    function updateFilter(text){
      setFilter(text.toLowerCase())
      if(text === '')
      {
        setViewingWorkloads(userWorkloads.slice(0, 50))
      }
      else
      {
        setViewingWorkloads(userWorkloads.filter((workload) => workload.label.toLowerCase().includes(text)).slice(0,50))
      }
    }
  
    const [selectAll, setSelectAll] = useState(false)
    function updateSelectAll(){
      setSelectAll(!selectAll)
      if(!selectAll)
      {
        viewingWorkloads.forEach((workload) => workload['enabled'] ? workload['selected'] = true : workload['selected'] = false)
        setSelectedWorkloads(viewingWorkloads.filter((workload) => workload['selected']))
      }
      else
      {
        viewingWorkloads.forEach((workload) => workload['selected'] = false)
        setSelectedWorkloads([])
      }
    }
  
    function selectWorkload(workload) {
      if(workload['selected'])
      {
        workload['selected'] = false
        selectedWorkloads.splice(selectedWorkloads.indexOf(workload), 1)
        setSelectedWorkloads(Object.values({...selectedWorkloads}))
      }
      else
      {
        workload['selected'] = true
        selectedWorkloads.push(workload)
        setSelectedWorkloads(Object.values({...selectedWorkloads}))
      }
      if(selectedWorkloads.length === viewingWorkloads.length)
      {
        setSelectAll(true)
      }
      else if(selectedWorkloads.length === 0)
      {
        setSelectAll(false)
      } 
    }

    // eslint-disable-next-line no-unused-vars
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
  return (
    <>
    {userWorkloads[0] === undefined ? 
    (<>
        <Grid container paddingBottom={2} justifyContent='center'>
          <Grid item>
            <VuiTypography variant="lg" fontWeight="bold" >
              {hasWorkloads ? 
              (
                <>
                  Loading your workloads...
                </>
              ) : 
              (
                <>
                  No workloads assigned that are eligible for the Universal Notifier. Check your Workload Catalog alignment.
                </>
              )}
            </VuiTypography>
          </Grid>
        </Grid>
      </>) : (
        <VuiCard variant="outlined" sx={{
          zIndex:0,
          overflow:'hidden',
        }}>
          <Grid container>
            <Grid item xs={12}>
              <VuiTypography variant="lg" fontWeight="bold">
                Filter workloads
              </VuiTypography>
              <VuiInput value={filter} placeholder={"Filter workloads"} onChange={e => updateFilter(e.target.value)}/>
            </Grid>
            <Grid item paddingTop={2}>
              <VuiTypography variant="button" fontWeight="bold"> 
                <VuiButton
                  variant='text' 
                  onClick={() => updateSelectAll()} 
                  disabled={!userCanEdit}>
                  {selectAll ? "Unselect All" : "Select All"}
                </VuiButton>
              </VuiTypography>
            </Grid>
            <Grid item xs={12} paddingTop={2} paddingBottom={15}>
              <Grid container >
                  <Grid item xs>
                    <VuiTypography variant="h5" fontWeight="bold"> 
                      Selected
                    </VuiTypography>
                  </Grid>
                  <Grid item lg={10} xs={6}>
                    <VuiTypography variant="h5" fontWeight="bold"> 
                      Workload
                    </VuiTypography>
                  </Grid>
                  <Grid item xs>
                    <VuiTypography variant="h5" fontWeight="bold"> 
                      Enabled
                    </VuiTypography>
                  </Grid>
              </Grid>
          </Grid>
          <Grid  
            sx={{
              maxHeight: 500, 
              overflowY: 'auto',
              transition: 'max-height 0.5s ease', 
              maxWidth:'auto',
              '& .MuiGrid-item': {
                minHeight: 100, 
              },
              '&::-webkit-scrollbar': {
                width: '12px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: colorScheme === 'dark' ? '#ffffff' :'#0e5387',
                borderRadius: '10px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#0000',
              },
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            container 
            rowSpacing={10}>
            {(viewingWorkloads.map((workload) => 
              <>
                <Grid container spacing={3}>
                  <Grid item xs>
                    <VuiCheckbox 
                    checked={workload['selected']} 
                    onClick={() => selectWorkload(workload)} 
                    disabled={!workload['enabled'] || !userCanEdit} />
                  </Grid>
                  <Grid item lg={10} xs={6}>
                    <VuiTypography variant="lg" fontWeight="bold">
                      {workload['label']}<br/>
                    </VuiTypography>
                    <VuiTypography variant="h6" fontWeight="bold">
                      Number of Vulnerabilities to Expect
                    </VuiTypography>
                    <VuiTypography variant="h6" fontWeight="regular">
                      <Grid container spacing={5} >
                        {typesEnabled.map((type) => 
                          <Grid item>
                            {type.toUpperCase()}: 
                            {<p><span> Crit-{workload[type+'CriticalFindings']} </span> {'\u00A0'} 
                            <span> High-{workload[type+'HighFindings']} </span> {'\u00A0'} 
                            <span> Med-{workload[type+'MediumFindings']} </span></p>}
                          </Grid>
                        )}
                      </Grid>
                    </VuiTypography>
                    <VuiTypography variant="h6" fontWeight="bold">
                      Current Severity Setting
                    </VuiTypography>
                    <VuiTypography variant="h6" fontWeight="regular" >
                      <Grid container spacing={5} >
                        {typesEnabled.map((type) => 
                          <Grid item>
                            {type.toUpperCase()}: {workload[type]}
                          </Grid>
                        )}
                      </Grid>
                    </VuiTypography>
                  </Grid>
                  <Grid item xs>
                    <VuiSwitch 
                    checked={workload['enabled']} 
                    onChange={() => determineWorkloadEnableOrDisable(workload)} 
                    />
                  </Grid>
                </Grid>
                </>
              ))}  
            </Grid>
          </Grid>
        </VuiCard>
      )}
      
    </>
  )
}

export default WorkloadsSelector;
