const local = {
    okta: {
        okta_url: "https://coxauto.okta.com/oauth2/default",
        client_id: "0oa1tomxc4zv8uBrC0h8",
        redirect_uri: "/auth/login",
        platform_url: "http://localhost:5071",
    },
};


const dev = {
    okta: {
        okta_url: "https://atg.oktapreview.com",
        client_id: "0oa293qedg1A8pLmE0h8",
        redirect_uri: "/auth/login",
        platform_url: "https://platform-api.security-engineering-np.coxautoinc.com",
    },
};

const prod = {
    okta: {
        okta_url: "https://coxauto.okta.com/oauth2/default",
        client_id: "0oa1tomxc4zv8uBrC0h8",
        redirect_uri: "/auth/login",
        platform_url: "https://platform-api.security-engineering.coxautoinc.com",
    },
};

let config;
switch (process.env.REACT_APP_STAGE) {
    case "production":
        config = prod;
        break
    case "dev":
        config = dev;
        break
    case "local":
        config = local;
        break
    default:
        config = prod;
        break
}

export default {
    ...config,
};
