// @mui material components
import { Grid } from "@mui/material";

//  components
import VuiTypography from "components/VuiTypography";
import VuiSnackbar from "components/VuiSnackbar";
import VuiButton from "components/VuiButton";


// General page components
import VuiSelect from "../../../../../components/VuiSelect";

// Data
import React, { useState, useEffect } from 'react';
import selectData from "./data";
import { createPostDataOnSelectedWorkloads, sendPayload} from '../../helpers';

//Okta
import VuiCard from "components/VuiCard";
import { useOktaAuth } from '@okta/okta-react';
function WorkloadSeveritySelector({userWorkloads, selectedWorkloads, resetSelection, viewingWorkloads, setViewingWorkloads, typesEnabled}) {
    const [dastValue, setdastValue] = useState('')
    const [cloudValue, setcloudValue] = useState('')
    const [sastValue, setsastValue] = useState('')
    const [scaValue, setscaValue] = useState('')
    const [canSelectSeverity, setCanSelectSeverity] = useState(selectedWorkloads.length > 0)
    useEffect(() => {
      setCanSelectSeverity(selectedWorkloads.length > 0)
    }, [selectedWorkloads])
  /*
  * This will be a function to save the preferences that a user has set after they have clicked the save button.
  */
  async function handleSave(token)
  {
    closeSaveWarningSB();
    const workloadsToUpdate = structuredClone(selectedWorkloads);    
    workloadsToUpdate.forEach((workload) => {
        workload['sca'] = scaValue === '' ?  workload['sca'] : scaValue
        workload['dast'] = dastValue === '' ?  workload['dast'] : dastValue
        workload['sast'] = sastValue === '' ?  workload['sast'] : sastValue
        workload['cloud'] = cloudValue === '' ?  workload['cloud'] : cloudValue
    }) 
    let payload = createPostDataOnSelectedWorkloads(workloadsToUpdate)

    const workloadStatus = await sendPayload(payload, token)
    if(workloadStatus.updated)
    {
        selectedWorkloads.forEach((workload) => {
          workload['sca'] = scaValue === '' ?  workload['sca'] : scaValue
          workload['dast'] = dastValue === '' ?  workload['dast'] : dastValue
          workload['sast'] = sastValue === '' ?  workload['sast'] : sastValue
          workload['cloud'] = cloudValue === '' ?  workload['cloud'] : cloudValue
      })
      setViewingWorkloads(Object.values({...viewingWorkloads}))
      opensavedInfoSB()
    }
    else
    {
      openCouldNotSaveWarningSB()
      resetSelection()
      return
    }
    if(!workloadStatus.releaseTrainEnabled)
    {
      openReleaseTrainNotEnabledSB()
    }

    resetSelection()
  }

  const [savedInfoSB, setOpenSavedInfoSB] = useState(false)
  const opensavedInfoSB = () => setOpenSavedInfoSB(true);
  const closesavedInfoSB = () => setOpenSavedInfoSB(false);

  const renderSavedInfo = (
      <VuiSnackbar
      color="info"
      icon="info"
      title="Preferences Saved"
      content="You have successfully saved your preferences for the Universal Notifier."
      open={savedInfoSB}
      onClose={closesavedInfoSB}
      close={closesavedInfoSB}
      button={false}
    >
    </VuiSnackbar>
  );


  const [couldNotSaveWarningSB, setCouldNotSaveWarningSB] = useState(false)
  const openCouldNotSaveWarningSB = () => setCouldNotSaveWarningSB(true);
  const closeCouldNotSaveWarningSB = () => setCouldNotSaveWarningSB(false);

  const renderCouldNotSaveWarning = (
    <VuiSnackbar
        color="error"
        icon="warning"
        title="Could not save preferences."
        content="You either do not have permissions to edit the Universal Notifier Preferences or the API call failed. Most likely you do not have correct permissions.
        Please contact the Product Security Engineering team if the issue persists and you are sure you have correct permissions."
        open={couldNotSaveWarningSB}
        onClose={closeCouldNotSaveWarningSB}
        close={closeCouldNotSaveWarningSB}
        button={false}
      >
      </VuiSnackbar>
  );
  

  const [saveWarningSB, setSaveWarningSB] = useState(false);
  const openSaveWarningSB = () => setSaveWarningSB(true);
  const closeSaveWarningSB = () => setSaveWarningSB(false);

  function saveWarning(token) {
      return <VuiSnackbar
          color="info"
          icon="warning"
          title="Saving new preferences"
          content="Are you sure you want to make these changes to the workload? *Changes will be reflected within 24 hours."
          open={saveWarningSB}
          onClose={closeSaveWarningSB}
          close={closeSaveWarningSB}
          button={true}
          callBack = {() => handleSave(token)}
          cancelCallBack = {() => closeSaveWarningSB()}
      >
      </VuiSnackbar>
  }

  const [releaseTrainNotEnabledSB, setReleaseTrainNotEnabledSB] = useState(false);
  const openReleaseTrainNotEnabledSB = () => setReleaseTrainNotEnabledSB(true);
  const closeReleaseTrainNotEnabledSB = () => setReleaseTrainNotEnabledSB(false);

  const renderReleaseTrainNotEnabled = (
      <VuiSnackbar
          color="warning" //may want to change color. will ask product
          icon="warning"
          title="Time Delay: Additional Permissions Needed"
          content="A workload you have changed settings for does not have its release train as a part of our Rally key.
           We will submit a ticket on our end to include this release train. You may see a delay of 2-3 days in Rally defects because of this."
          open={releaseTrainNotEnabledSB}
          onClose={closeReleaseTrainNotEnabledSB}
          close={closeReleaseTrainNotEnabledSB}
          button={false}
      >
      </VuiSnackbar>
  );


  function handleChange(severity, type){
    if(type === 'sca')
    {
      setscaValue(severity)
    }
    else if(type === 'sast')
    {
        setsastValue(severity)
    }
    else if(type === 'dast')
    {
        setdastValue(severity)
    }
    else if(type === 'cloud')
    {
        setcloudValue(severity)
    }
  }

  const { authState, oktaAuth } = useOktaAuth();
  return (
    <>
      {canSelectSeverity ? 
      (
        <VuiCard sx={{
          zIndex:10,
          overflow:'visible',
          marginTop:5,
          position: 'sticky',
          bottom: 20,
          }}
          hasBorder = {true}>
          <Grid container spacing={3} >
            {typesEnabled.map((type) => 
            <>
              <Grid item xs='auto' sx={{ justifyContent: 'flex-start' }}> 
                <VuiTypography variant="lg" fontWeight="bold">
                  {type.toUpperCase()}
                </VuiTypography>
              </Grid>
              <Grid item xs={2} sx={{  justifyContent: 'flex-start' }}>
                <VuiSelect 
                name={type} 
                placeholder='Select Severity' 
                options={selectData.severity} 
                onChange={(data, name) => handleChange(data.value, name.name)} 
                disabled={selectedWorkloads.length === 0 ? true : false}
                menuPlacement="top"
                />
              </Grid>
            </>
            )}
            <Grid item xs>
            {/* This item acts as a flexible spacer so that the Save button can appear on the same row but spaced out */}
            </Grid>
            <Grid item sx={{ justifyContent: 'flex-end' }}>
              <VuiButton onClick={() => openSaveWarningSB()} disabled={selectedWorkloads.length === 0}>
                Save
              </VuiButton>
            </Grid>
          </Grid>
        </VuiCard>
      ) : 
      (
        <>
        </>
      )}
      
      {saveWarning(authState.accessToken.accessToken)}
      {renderCouldNotSaveWarning}
      {renderSavedInfo}
      {renderReleaseTrainNotEnabled}
    </>
  )
}

export default WorkloadSeveritySelector
