import {OktaAuth} from "@okta/okta-auth-js";
import config from "../../../config";
const base_url = config.okta.platform_url
export async function getUserWorkloads(accessToken, impersonatedUser)
{
  let options = {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
    },
  };
  let url = base_url + '/WLC'
  if (impersonatedUser != null) {
    url = url + '?impersonationUser=' + impersonatedUser;
  }

  return await fetch(url, options)
    .then((response) => response.json())
    .then((data) => {
        return data;
    })
    .catch((error) => {
      console.error('API Error:', error);
    });
}


/*
 * This will be a function to check if the user who is logged in can make edits
*/
export async function checkPermissions(accessToken)
{
  return true
}


/*
 * This will be a function to create the data in a consistent way to be sent to the backend with the selected workloads. It contains everything that is currently in the database and can be
 * modified to fit any changes in the future.
 * 
 * 
*/
  export function createPostDataOnSelectedWorkloads(selectedClouds) {
    // Populate the object with the provided data
    const payload = []

    selectedClouds.forEach((workload) => {
      payload.push({
        workloadName: workload['workloadName'],
        workloadNumber: workload['workloadNumber'],
        releaseTrainName: workload['releaseTrainName'],
        releaseTrainNumber: workload['releaseTrainNumber'],
        enabled: workload['enabled'],
        sast : workload['sast'],
        dast : workload['dast'],
        sca : workload['sca'],
        cloud : workload['cloud'],
        value: workload['value'],
        label : workload['label']
      })
    })

    return payload;
  }

  /*
 * This will be a function to create the data in a consistent way to be sent to the backend when enabling a workload. It contains everything that is currently in the database and can be
 * modified to fit any changes in the future.
 * 
 * 
*/
export function createPostDataOnEnabledWorkload(workloadToEnable) {
  // Populate the object with the provided data
  const payload = [];
  payload.push({
    enabled : workloadToEnable['enabled'],
    releaseTrainNumber : workloadToEnable['releaseTrainNumber'],
    releaseTrainName : workloadToEnable['releaseTrainName'],
    workloadNumber : workloadToEnable['workloadNumber'],
    workloadName : workloadToEnable['workloadName'],
    sast : workloadToEnable['sast'],
    dast : workloadToEnable['dast'],
    sca : workloadToEnable['sca'],
    cloud : workloadToEnable['cloud'],
    value: workloadToEnable['value'],
    label : workloadToEnable['label']
  })

  return payload;
}

  /*
 * This function will send the payload that is created in createPostData back to the API to update the workload(s) that are in the payload
 * 
  */
  export async function sendPayload(payload, token)
  {
    let options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json', // Specify the content type as JSON
            'Authorization': 'Bearer ' + token
            // You can add other headers if needed
        },
        
        body: JSON.stringify(payload), // Convert the data to JSON string
    };
    //This is the post endpoint that will be made to receive the data that they entered when they pressed save
    return await fetch(base_url+'/WLC', options)
        .then((response) => 
        {
          if (!response.ok) {
            throw new Error(`Could not update workload! status: ${response.status}`)
          }
          return response.json();
        })
        .then((data) => {
          return data
        })
        .catch((error) => {
        // Handle API error if needed
          console.error('API Error:', error);
          return false
        });
  }
