import { forwardRef } from "react";
import PropTypes from "prop-types";
import VuiCheckboxRoot from "components/VuiCheckbox/VuiCheckboxRoot";

const VuiCheckbox = forwardRef(({color, children, ...rest}, ref) => (
    <VuiCheckboxRoot 
    {...rest}
    ref = {ref}
    >
        {children}
    </VuiCheckboxRoot>
))


VuiCheckbox.defaultProps = {

}


export default VuiCheckbox;