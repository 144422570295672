

// @mui material components
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

export default styled(Switch)(({ theme, ownerState }) => {
  const { palette, functions, borders, type } = theme;
  const { color } = ownerState;
  const { white, secondary, grey, dark } = palette;
  // styles for the button with variant="contained"
  const containedStyles = () => {
    // background color value
    const backgroundValue = palette[color] ? palette[color].main : white.main;
    return {
      "&.MuiSwitch-root": {
        "& .MuiSwitch-thumb": {
          backgroundColor: type === "lightTheme" ? white.main : dark.main,
        },
        "& .MuiSwitch-track": {
          backgroundColor: type === "lightTheme" ? `${grey[500]} !important` : `${grey[400]} !important` ,
        },
        "& .Mui-checked+.MuiSwitch-track": {
          backgroundColor: type === "lightTheme" ? `${dark.main} !important` : `${white.main} !important`,
        },
      },
    };
  };

  return {
    ...containedStyles(),
  };
});
