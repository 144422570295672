

import { useState, useEffect, useMemo } from "react";

// react-router components
import { Route, Switch, Redirect, useLocation, useHistory } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

//  React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

//  React themes
import themeDark from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import themelight from "assets/theme-light"

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

//Okta
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security, SecureRoute, useOktaAuth } from '@okta/okta-react';


//  React routes
import routes from "routes";

//  React contexts
import { useVisionUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Plugins custom css
import "assets/theme/base/plugins.css";
// import config
import "./config"
import config from "./config";

export default function App() {
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  //Okta
  const oktaAuth = new OktaAuth({
    issuer: config.okta.okta_url,
    clientId: config.okta.client_id,
    redirectUri: config.okta.redirect_uri,
  });


  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  //Get system color scheme
  const[colorScheme, setColorScheme] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light")
  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
    setColorScheme(event.matches ? "dark" : "light")
  });

  //Make Routes
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        if(route.authRequired)
        {
          return (
            <SecureRoute path={route.route} component={route.component} key={route.key}/>
          )
        }
        else
        {
          return (
            <Route path={route.route} component={route.component} key={route.key}/>
          )
        }
      }

      return null;
    });
  
  
  return direction === "rtl" ? (
    
      <CacheProvider value={rtlCache}>
        <ThemeProvider theme={themeRTL}>
          <CssBaseline />
          {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brandName={
                  <div>
                    Product Security <br/> Portal
                  </div>
                }
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/applications/universal-notifier" />
              {/* <Redirect from="*" to='/auth/login' /> */}
            </Switch>
          </Security>
        </ThemeProvider>
      </CacheProvider>
  ) : (
      <ThemeProvider theme={colorScheme === "light" ? themelight : themeDark}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
        <Sidenav
          brandName={
            <div>
              Product Security <br/> Portal
            </div>
          }
          routes={routes}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
          theme={colorScheme === "light" ? themelight : themeDark}
        />
      <Configurator />
      </>
        )}
        {layout === "vr" && <Configurator />}
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <Switch>
            {getRoutes(routes)}
            <Redirect from="*" to="/applications/universal-notifier" />
            {/* <Redirect from="/" to='/auth/login' /> */}
          </Switch>
        </Security>
      </ThemeProvider>
  );
}
